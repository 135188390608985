
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Paper, Grid, Snackbar, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import LeafletMap from '../../Component/Map/LeafletMap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const JobModal = ({ token }) => {
    const [newTask, setNewTask] = useState({
        client_name: '',
        location: '',
        road: '',
        city: '',
        eircode: '',
        county: '',
        latitude: '',
        longitude: '',
        instruction: '',
        email: '',
        phone_no: '',
        recurringmounth: 0,
        recurringdate: '',
        recurring_status: false
    });
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [counties, setCounties] = useState([]);  // Store counties data here
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [apirespons, setApiResponse] = useState([]);  // Store full API response
    const nav = useNavigate();

    // Fetch location data using Eircode
    const fetchLocationByEircode = async (eircode) => {
        try {
            const response = await axios.get('https://api.opencagedata.com/geocode/v1/json', {
                params: {
                    q: `${eircode}, Ireland`,
                    key: '4a5ebad90e09432bb997b83538bf7d56', // Replace with your OpenCage API key
                    language: 'en',
                    pretty: 1,
                },
            });

            if (response.data.results.length > 0) {
                setApiResponse(response.data.results); 
                console.log(response.data.results) // Store full API response for later use
                const { geometry, formatted, components } = response.data.results[0];

                const lat = geometry.lat;
                const lng = geometry.lng;

                if (lat !== undefined && lng !== undefined) {
                    const county = components.county || '';

                    setNewTask(prevTask => ({
                        ...prevTask,
                        location: formatted,
                        road: components.road || '',
                        city: components.postal_city || components.town || '',
                        county: county,
                        latitude: lat,
                        longitude: lng,
                    }));

                    setSelectedLocation({
                        lat: parseFloat(lat),
                        lng: parseFloat(lng),
                        location: formatted,
                    });

                    setCounties([{ county }]);  // Store county based on response
                } else {
                    throw new Error('Latitude and Longitude are not defined.');
                }
            } else {
                throw new Error('No location found for this Eircode.');
            }
        } catch (error) {
            // console.error('Error fetching location by Eircode:', error);
            setSnackbarMessage('Error fetching location. Please check the Eircode.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    // Handle Eircode change and trigger location fetch
    const handleEircodeChange = async (e) => {
        const eircode = e.target.value;
        setNewTask(prevTask => ({ ...prevTask, eircode }));

        if (eircode) {
            await fetchLocationByEircode(eircode);
        } else {
            setNewTask(prevTask => ({
                ...prevTask,
                location: '',
                road: '',
                city: '',
                county: '',
                latitude: '',
                longitude: '',
            }));
            setSelectedLocation(null);
            setCounties([]);
        }
    };

    // Handle county change and autofill relevant fields
    const handleCountyChange = (e) => {
        const county = e.target.value;
        setNewTask(prevTask => ({ ...prevTask, county }));

        // Find the corresponding county data from the API response
        const selectedCounty = apirespons.find(result => result.components.county === county);
        
        if (selectedCounty) {
            const { components, geometry } = selectedCounty;
            setNewTask(prevTask => ({
                ...prevTask,
                road: components.road || '',
                city: components.postal_city || components.town || '',
                location: selectedCounty.formatted,
                latitude: geometry.lat,
                longitude: geometry.lng,
            }));
        }
    };

    // Handle map location select
    const handleMapLocationSelect = (lat, lng) => {
        setNewTask(prevTask => ({ ...prevTask, latitude: lat, longitude: lng }));
    };

    // Handle form submission
    const handleSubmit = async () => {
        setSnackbarOpen(false);
    
        const requiredFields = [
            newTask.client_name,
            newTask.location,
            // newTask.county,
            newTask.instruction,
            newTask.latitude,
            newTask.longitude,
            newTask.eircode,
            newTask.email,
            newTask.phone_no,
        ];
    
        if (requiredFields.some(field => !field)) {
            setSnackbarMessage('All fields are required');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
    
        try {
            // Check if recurringdate is a valid date string or not
            const recurringDate = newTask.recurringdate && !isNaN(Date.parse(newTask.recurringdate)) ? newTask.recurringdate : null;
    
            const data = {
                client_name: newTask.client_name,
                location: newTask.location,
                road: newTask.road,
                city: newTask.city,
                postcode: newTask.eircode,
                area: newTask.county,
                latitude: newTask.latitude,
                longitude: newTask.longitude,
                instruction: newTask.instruction,
                email: newTask.email,
                phone_no: newTask.phone_no,
                recurringdate: recurringDate,  // Ensure it's either a valid date or null
                recurringmounth: newTask.recurringmounth,
                recurring_status: newTask.recurring_status
            };
    
            await axios.post(
                `${process.env.REACT_APP_API_URL}apiv1/add/job`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            setSnackbarMessage('Job added successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setNewTask({
                client_name: '',
                location: '',
                road: '',
                eircode: '',
                county: '',
                latitude: '',
                longitude: '',
                instruction: '',
                email: '',
                phone_no: '',
                recurringdate: '',
                recurringmounth: 0,
                recurring_status: false
            });
            setSelectedLocation(null);
            nav('/jobs');
        } catch (error) {
            // console.error('Error adding job:', error);
            setSnackbarMessage('Error adding job. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    }
    

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    // Handle Recurring Status Change
    const handleRecurringStatusChange = (e) => {
        setNewTask(prevTask => ({ ...prevTask, recurring_status: e.target.checked }));
    };

    return (
        <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: '#f4f6f9' }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, color: '#333' }}>Create New Job</Typography>

            <Paper elevation={6} sx={{ p: 4, flexGrow: 1, overflow: 'auto', borderRadius: 2, boxShadow: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            label="Client Name"
                            variant="outlined"
                            fullWidth
                            value={newTask.client_name}
                            onChange={(e) => setNewTask({ ...newTask, client_name: e.target.value })}
                            sx={{
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#555',
                                },
                            }}
                        />
                    </Grid>

                    {/* Add Email, phone_no, and Instruction Inputs */}
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            value={newTask.email}
                            onChange={(e) => setNewTask({ ...newTask, email: e.target.value })}
                            sx={{
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#555',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                            value={newTask.phone_no}
                            onChange={(e) => setNewTask({ ...newTask, phone_no: e.target.value })}
                            sx={{
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#555',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Instruction"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={newTask.instruction}
                            onChange={(e) => setNewTask({ ...newTask, instruction: e.target.value })}
                            sx={{
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#555',
                                },
                            }}
                        />
                    </Grid>

                    {/* Recurring Status */}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={newTask.recurring_status}
                                    onChange={handleRecurringStatusChange}
                                    color="primary"
                                />
                            }
                            label="Is this a recurring job?"
                        />
                    </Grid>
                    

                    {/* Recurring Date and Month - Only visible if recurring_status is true */}
                    {newTask.recurring_status && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Recurring Date"
                                    type="date"
                                    variant="outlined"
                                    fullWidth
                                    value={newTask.recurringdate}
                                    onChange={(e) => setNewTask({ ...newTask, recurringdate: e.target.value })}
                                    sx={{
                                        mb: 3,
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>


<FormControl fullWidth sx={{ mb: 3 }}>
    <InputLabel>Recurring Week</InputLabel>
    <Select
        value={newTask.recurringmounth || 0}  // Default to 0 (or 1) if undefined
        onChange={(e) =>  setNewTask({ ...newTask, recurringmounth: e.target.value })}
        label="Recurring Month"
        sx={{
            '& .MuiOutlinedInput-root': {
                borderRadius: 2,
            },
        }}
    >
     <MenuItem value={1}>Per Week / Every One Week</MenuItem>

        {/* Generate options for Every 2 to 12 months */}
        {Array.from({ length: 3 }, (_, index) => (  // We start from 2 to 12 months (length 11)
            <MenuItem key={index + 2} value={index + 2}>
                {index + 2 === 4 
                    ? 'Every 4 Weeks' // Handle 12 months specifically
                    : `Every ${index + 2} Week${index + 2 > 1 ? 's' : ''}`}
            </MenuItem>
        ))}
    </Select>
</FormControl>



                            </Grid>
                        </>
                    )}

                 
                  <Grid item xs={12} sm={6}>
                      <TextField
                            label="Eircode"
                            variant="outlined"
                            fullWidth
                            value={newTask.eircode}
                            onChange={handleEircodeChange}
                            sx={{
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                          <TextField
                            label="Location"
                            variant="outlined"
                            fullWidth
                            value={newTask.location}
                            onChange={(e) => setNewTask({ ...newTask, location: e.target.value })}
                            sx={{
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel>County</InputLabel>
                            <Select
                                value={newTask.county}
                                onChange={handleCountyChange}
                                label="County"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                    },
                                }}
                            >
                                {apirespons.map((county, index) => (
                                    <MenuItem key={index} value={county.components.county}>
                                        {county.components.county}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{
                                backgroundColor: '#1976d2',
                                '&:hover': {
                                    backgroundColor: '#1565c0',
                                },
                                padding: '10px 20px',
                                borderRadius: 2,
                                boxShadow: 3,
                                fontWeight: 600,
                                letterSpacing: '1px',
                            }}
                        >
                            Add Job
                        </Button>
                    </Grid>

                    {/* Map Component */}
                    <Grid item xs={12}>
                        <Box sx={{ mb: 3 }}>
                            <LeafletMap
                                onLocationSelect={handleMapLocationSelect}
                                selectedLocation={selectedLocation}
                                style={{ height: '300px', width: '100%', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {/* Snackbar */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default JobModal;



