
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Modal, Paper, Grid, Snackbar, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Alert } from '@mui/material';
import axios from 'axios';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    height: '100vh',
};

const EditJobModal = ({ open, onClose, job, onJobUpdated, token }) => {
    const [newTask, setNewTask] = useState({ ...job });
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [apiResponse, setApiResponse] = useState([]); // Store API response
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        setNewTask({ ...job });
    }, [job]);

    useEffect(() => {
        // Fetch location data when postcode changes
        const fetchLocationData = async () => {
            try {
                const postcode = newTask.postcode;
                const response = await axios.get('https://api.opencagedata.com/geocode/v1/json', {
                    params: {
                        q: `${postcode}, Ireland`,
                        key: '4a5ebad90e09432bb997b83538bf7d56', // Replace with your OpenCage API key
                        language: 'en',
                        pretty: 1,
                    },
                });

                if (response.data.results.length > 0) {
                    setApiResponse(response.data.results); // Store the API response
                } else {
                    setApiResponse([]);
                    setSnackbarMessage('No location found for this postcode.');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                }
            } catch (error) {
                // console.error('Error fetching location data:', error);
                setSnackbarMessage('Error fetching location data.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        if (newTask.postcode) {
            fetchLocationData();
        }
    }, [newTask.postcode]); // Trigger when postcode changes

    const handleRecurringStatusChange = (event) => {
        setNewTask({
            ...newTask,
            recurring_status: event.target.checked,
            recurringdate: '', // Reset recurring date if status is unchecked
            recurringmounth: 0, // Reset recurring month if status is unchecked
        });
    };

    const handlePostcodeChange = (e) => {
        setNewTask({ ...newTask, postcode: e.target.value });
    };

    const handleCountyChange = (e) => {
        const selectedCounty = e.target.value;
        const countyData = apiResponse.find(result => result.components.county === selectedCounty);
        if (countyData) {
            const { formatted, geometry } = countyData;
            setNewTask({
                ...newTask,
                county: selectedCounty,
                location: formatted,
                latitude: geometry.lat,
                longitude: geometry.lng,
            });

            setSelectedLocation({
                lat: geometry.lat,
                lng: geometry.lng,
                location: formatted,
            });
        }
    };

    const handleLocationChange = (e) => {
        setNewTask({ ...newTask, location: e.target.value });
    };

    const handleSubmit = async () => {
        let formattedRecurringDate = newTask.recurringdate;

        if (newTask.recurringdate && !isValidDate(newTask.recurringdate)) {
            formattedRecurringDate = null;
        }

        const updatedTask = {
            ...newTask,
            recurringdate: formattedRecurringDate, 
        };

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}apiv1/job/${job.id}`, updatedTask, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            onJobUpdated(); 
            setSnackbarMessage('Job updated successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            onClose(); 
        } catch (error) {
            // console.error('Error updating job:', error);
            setSnackbarMessage('Error updating job.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const isValidDate = (date) => {
        return !isNaN(Date.parse(date));
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, color: '#333' }}>
                        Edit Job
                    </Typography>

                    <Paper elevation={6} sx={{ p: 4, flexGrow: 1, overflow: 'auto', borderRadius: 2, boxShadow: 2 }}>
                        <Grid container spacing={3}>
                            {/* Client Name */}
                            <Grid item xs={12}>
                                <TextField
                                    label="Client Name"
                                    fullWidth
                                    value={newTask.client_name}
                                    onChange={(e) => setNewTask({ ...newTask, client_name: e.target.value })}
                                    sx={{ mb: 3 }}
                                />
                            </Grid>

                            {/* Recurring Status Checkbox */}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={newTask.recurring_status}
                                            onChange={handleRecurringStatusChange}
                                            color="primary"
                                        />
                                    }
                                    label="Is this a recurring job?"
                                />
                            </Grid>

                            {/* Recurring Date and Month */}
                            {newTask.recurring_status && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Recurring Date"
                                            type="date"
                                            fullWidth
                                            value={newTask.recurringdate || ''}
                                            onChange={(e) => setNewTask({ ...newTask, recurringdate: e.target.value })}
                                            sx={{ mb: 3 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth sx={{ mb: 3 }}>
                                            <InputLabel>Recurring Week</InputLabel>
                                            <Select
                                                value={newTask.recurringmounth || 0}
                                                onChange={(e) => setNewTask({ ...newTask, recurringmounth: e.target.value })}
                                                label="Recurring Month"
                                            >
                                               <MenuItem value={1}>Per Week / Every 1 Weeks</MenuItem>
                                                <MenuItem value={2}>Every 2 Weeks</MenuItem>
                                                <MenuItem value={3}>Every 3 Weeks</MenuItem>
                                                <MenuItem value={4}>Every 4 Weeks</MenuItem>
 
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}

                            {/* Postcode */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Postcode"
                                    fullWidth
                                    value={newTask.postcode}
                                    onChange={handlePostcodeChange}
                                    sx={{ mb: 3 }}
                                />
                            </Grid>

                            {/* Location */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Location"
                                    fullWidth
                                    value={newTask.location}
                                    onChange={handleLocationChange}
                                    sx={{ mb: 3 }}
                                />
                            </Grid>

                            {/* County */}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel>County</InputLabel>
                                    <Select
                                        value={newTask.county}
                                        onChange={handleCountyChange}
                                        label="County"
                                    >
                                        {apiResponse.map((county, index) => (
                                            <MenuItem key={index} value={county.components.county}>
                                                {county.components.county}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Email */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    fullWidth
                                    value={newTask.email}
                                    onChange={(e) => setNewTask({ ...newTask, email: e.target.value })}
                                    sx={{ mb: 3 }}
                                />
                            </Grid>

                            {/* Phone Number */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Phone Number"
                                    fullWidth
                                    value={newTask.phone_no}
                                    onChange={(e) => setNewTask({ ...newTask, phone_no: e.target.value })}
                                    sx={{ mb: 3 }}
                                />
                            </Grid>

                            {/* Instructions */}
                            <Grid item xs={12}>
                                <TextField
                                    label="Instructions"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={newTask.instruction}
                                    onChange={(e) => setNewTask({ ...newTask, instruction: e.target.value })}
                                    sx={{ mb: 3 }}
                                />
                            </Grid>

                            {/* Submit Button */}
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={handleSubmit}>
                                    Update Job
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Modal>

            {/* Snackbar */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default EditJobModal;
